import convert from './assets/convert.svg';
import arrowRight from './assets/arrow-right.svg';
import google from './assets/google.svg';
import apple from './assets/apple.svg';
import heroImg from './assets/heroImg.svg';
import hive from './assets/hive.svg';
import { motion } from 'framer-motion';

const Hero = () => {
    return ( 
        <>
        <div className=" w-full justify-between flex flex-col md:flex-row md:px-14 md:py-16">
            <div className=" w-full px-4 md:w-[48%] mt-32 md:mt-16 z-10">
                <motion.p 
                initial={{opacity:0, y:25}}
                animate={{opacity:1, y:0}}
                transition={{type:'tween', delay:1.3, duration:0.5}}
                className=" font-SpaceOne font-normal text-[32px] md:text-4xl">Your One-Stop DeFi Hub</motion.p>
                <motion.p 
                initial={{opacity:0, y:25}}
                animate={{opacity:1, y:0}}
                transition={{type:'tween', delay:1.8, duration:0.5}}
                className=" font-Oxanium font-normal text-base md:text-xl mt-2">Earn, Trade, and Thrive in the New Era! </motion.p>
                <motion.span 
                initial={{opacity:0, y:25}}
                animate={{opacity:1, y:0}}
                transition={{type:'tween', delay:2.3, duration:0.5}}
                className=' w-full flex items-center space-x-3 mt-8 md:mt-10 relative'>
                    <img src={ convert } className=' w-4 h-4 md:w-5 md:h-5' alt="" />
                    <p className=' font-Oxanium text-base md:text-xl font-medium'>Trade Bitcoin for free</p>
                    <img src={ arrowRight } className=' absolute right-0 md:static ' alt="" />
                </motion.span>
                <motion.button 
                initial={{opacity:0, y:25}}
                animate={{opacity:1, y:0}}
                transition={{type:'tween', delay:2.8, duration:0.5}}
                className=' button-50 w-full py-3 md:py-5 mt-6 text-base md:text-xl font-normal font-Oxanium'>Sign up with Email or Mobile</motion.button>
                <motion.div
                initial={{opacity:0, y:25}}
                animate={{opacity:1, y:0}}
                transition={{type:'tween', delay:3.3, duration:0.5}}
                className=' w-full mt-8 flex items-center'>
                    <span className='w-[35%] md:w-[40%] border-t border-white'></span>
                    <p className=' font-Oxanium text-sm font-normal text-center w-[30%] md:w-[25%]'>Or log in with</p>
                    <span className='w-[35%] md:w-[40%] border-t border-white'></span>
                </motion.div>
                <motion.div
                initial={{opacity:0, y:25}}
                animate={{opacity:1, y:0}}
                transition={{type:'tween', delay:3.8, duration:0.5}}
                className=' mt-8 w-full flex justify-between'>
                    <button className=' w-[45%] py-3 flex items-start md:items-center justify-center space-x-3 bg-[#333333] rounded-[10px]'>
                        <img src={ google } className='w-4 h-4 md:w-5 md:h-5' alt="" />
                        <p className=' font-Oxanium text-sm md:text-lg font-normal'>Google</p>
                    </button>
                    <button className=' w-[45%] py-3 flex items-start md:items-center justify-center space-x-3 bg-[#333333] rounded-[10px]'>
                        <img src={ apple } className='w-4 h-4 md:w-5 md:h-5' alt="" />
                        <p className=' font-Oxanium text-sm md:text-lg font-normal'>Apple</p>
                    </button>
                </motion.div>
            </div>
            <div className="w-full md:w-[48%] mt-8 md:mt-0 z-10">
                <img src={ heroImg } className=' z-10' alt="" />
            </div>
        </div>
        <div className=' my-16 md:mt-0 text-center md:text-left md:justify-between items-center flex flex-col md:flex-row px-4 md:px-14 pb-12 relative space-y-[64px] md:space-y-0'>
            <span className=' font-Oxanium'>
                <p className=' font-medium text-[32px] md:text-4xl'>$10 billion</p>
                <p className=' font-normal text-base md:text-lg'>24h trading volume on Bithive</p>
            </span>
            <span className=' font-Oxanium'>
                <p className=' font-medium text-[32px] md:text-4xl'>350+</p>
                <p className=' font-normal text-base md:text-lg'>Cryptocurrencies listed</p>
            </span>
            <span className=' font-Oxanium'>
                <p className=' font-medium text-[32px] md:text-4xl'>100 million</p>
                <p className=' font-normal text-base md:text-lg'>Registered users</p>
            </span>
            <span className=' font-Oxanium'>
                <p className=' font-medium text-[32px] md:text-4xl'>{'< 0.10%'}</p>
                <p className=' font-normal text-base md:text-lg'>Lowest transaction fees</p>
            </span>
            <img src={ hive } className=' hidden md:block z-0 absolute left-0 bottom-0 w-[15%]' alt="" />
        </div>
        </>
     );
}
 
export default Hero; 