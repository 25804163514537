import logo from './assets/logo.svg'
import arrowDown from './assets/arrow-down.svg';
import search from './assets/search.svg';
import noti from './assets/notification.svg';
import inbox from './assets/inbox.svg';
import globe from './assets/global.svg';
import sun from './assets/sun.svg';
import gift from './assets/gift.svg';
import x from './assets/X.svg';
import { motion } from 'framer-motion';
import buy from './assets/buy.svg';
import shop from './assets/shop.svg';
import trade from './assets/trade.svg';
import docu from './assets/document-text.svg';
import coin from './assets/coin.svg';
import teacher from './assets/teacher.svg';
import solid from './assets/solid.svg';


const Navbar = () => {

    function overlay(){
        //check classlist
        const overlayDiv = document.getElementById('overlay');
        if(overlayDiv.classList.contains('-translate-x-[100%]')){
            overlayDiv.classList.remove('-translate-x-[100%]')
        }
        else if(!overlayDiv.classList.contains('-translate-x-[100%]')){
            overlayDiv.classList.add('-translate-x-[100%]')
        }
    };


    return ( 
        <>
        <div id="overlay" className=" w-full bg-[#121212] h-[100Vh] backdrop-blur-xl p-6 -translate-x-[100%] shadow transition-all duration-700 top-[78px] fixed z-[11]">
            <div className="w-full flex flex-col space-y-8">
                <div className=' w-full flex flex-row justify-between mt-5'>
                    <button className=' border border-[#333333] py-3 w-[48%] text-[#fff] text-center font-Oxanium font-normal rounded-md'>Login</button>
                    <button className=' bg-[#F3BA2F] py-3 w-[48%] text-[#000] text-center font-Oxanium font-normal rounded-md'>Register</button>
                </div>
                <button className=" text-left font-normal cursor-pointer text-base text-[#fff] font-Oxanium flex items-end space-x-3 ">
                    <img src={ buy } alt="" />
                    <p className=' '>Buy Crypto</p>
                </button>
                <button className=" text-left font-normal cursor-pointer text-base text-[#fff] font-Oxanium flex items-end space-x-3 ">
                    <img src={ shop } alt="" />
                    <p className=' '>Market</p>
                </button>
                <button className=" text-left font-normal cursor-pointer text-base text-[#fff] font-Oxanium flex items-end space-x-3 ">
                    <img src={ trade } alt="" />
                    <p className=' '>Trade</p>
                </button>
                <button className=" text-left font-normal cursor-pointer text-base text-[#fff] font-Oxanium flex items-end space-x-3 ">
                    <img src={ docu } alt="" />
                    <p className=' '>Contract</p>
                </button>
                <button className=" text-left font-normal cursor-pointer text-base text-[#fff] font-Oxanium flex items-end space-x-3 ">
                    <img src={ coin } alt="" />
                    <p className=' '>Earn</p>
                </button>
               <button className=" text-left font-normal cursor-pointer text-base text-[#fff] font-Oxanium flex items-end space-x-3 ">
                    <img src={ teacher } alt="" />
                    <p className=' '>Learn</p>
               </button>
               <button className=" text-left font-normal cursor-pointer text-base text-[#fff] font-Oxanium flex items-end space-x-3">
                    <img src={ solid } alt="" />
                    <p className=' '>Referral</p>
               </button>
            </div>
        </div>
        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{type:'tween', duration:0.8, delay:0.5}}
        className=" w-full py-5 md:py-9 px-4 md:px-14 flex items-center fixed md:static top-0 bg-[#121212] justify-between border-b md:border-b-0 border-[#333333] z-[99999]">
            <span className=" flex items-center space-x-1">
                <img src={ logo } alt="" />
                <p className=" font-SpaceOne text-[#F3BA2F] font-normal md:text-lg text-xl">BITHIVE</p>
            </span>
            <div onClick={ overlay } className="menu-icon md:hidden">
                <input className="menu-icon__cheeckbox" type="checkbox" />
                <div className=' lg:hidden'>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className='hidden md:flex items-center font-normal text-sm space-x-5'>
                <button className=' flex items-center font-Oxanium space-x-2'>
                    <p>Buy Crypto</p>
                    <img src={ arrowDown } alt="" />
                </button>
                <button className=' flex items-center font-Oxanium space-x-2'>
                    <p>Market</p>
                    <img src={ arrowDown } alt="" />
                </button>
                <button className=' flex items-center font-Oxanium space-x-2'>
                    <p>Trade</p>
                    <img src={ arrowDown } alt="" />
                </button>
                <button className=' flex items-center font-Oxanium space-x-2'>
                    <p>Earn</p>
                    <img src={ arrowDown } alt="" />
                </button>
                <button className=' flex items-center font-Oxanium space-x-2'>
                    <p>More</p>
                    <img src={ arrowDown } alt="" />
                </button>
            </div>
            <div className='hidden md:flex items-center font-normal text-sm space-x-4'>
                <img src={ search } className='w-4 h-4' alt="" />
                <button className=' flex items-center font-Oxanium py-2 px-2'>
                    <p>Login</p>
                </button>
                <button className=' flex items-center justify-center text-center text-[#121212] bg-[#F3BA2F] z-10 rounded-md font-Oxanium pb-1.5 pt-2 px-4'>
                    <p>Register</p>
                </button>
            </div>
            <div className='hidden md:flex items-center font-normal text-base z-10 space-x-5'>
                <img src={ noti } className=' w-4 h-4' alt="" />
                <img src={ inbox } className=' w-4 h-4' alt="" />
                <img src={ globe } className=' w-4 h-4' alt="" />
                <img src={ sun } className=' w-4 h-4' alt="" />
            </div>
        </motion.div>
        <motion.div
         initial={{opacity:0, y:-10}}
         animate={{opacity:1, y:0}}
         transition={{type:'tween', duration:0.8, delay:1.3}}
        className='hidden md:flex justify-center items-center relative text-center bg-[#FDD65ACC] py-2 z-10'>
            <img src={ gift } alt="" />
            <p className=' text-sm font-Oxanium font-normal ml-2 text-[#000]'>Register now - Get up to 100 USDT in trading fee (for verified users)</p>
            <button className=' py-1.5 px-6 bg-[#1a1a1a] text-white rounded-md font-Oxanium font-normal text-sm ml-6'>Sign up now</button>
            <img src={ x } className=' absolute right-4' alt="" />
        </motion.div>
        </>
     );
}
 
export default Navbar;