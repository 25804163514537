import { useState } from "react";

const Prices = () => {

    const [activeButton, setActiveButton] = useState('hotSpot');

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    return ( 
        <>
        <div className=" py-10 px-4 md:px-16 font-Oxanium">
            <p className=" text-3xl font-medium">Crypto Prices</p>
            <div className=" flex border w-full border-[#333333] rounded-[30px] mt-8">
                <div className=" w-[70%] p-8 border-r border-[#333] h-full">
                    <div className=" flex">
                        <button
                            className={`font-medium font-LatOxaniumo text-sm pb-1 px-2 transition-all ${
                                activeButton === 'hotSpot'
                                    ? 'border-b border-[#F3BA2F] text-[#F3BA2F]'
                                    : ''
                            }`}
                            onClick={() => {handleButtonClick('hotSpot')}}
                        >
                        Hot Spot
                        </button>

                        <button
                        className={`font-medium font-Oxanium text-sm pb-1 px-2 transition-all ${
                            activeButton === 'hotContract'
                                ? 'border-b border-[#F3BA2F] text-[#F3BA2F]'
                                : ''
                        }`}
                        onClick={() => {handleButtonClick('hotContract')}}
                        >
                        Hot Contract
                        </button>
                    </div>
                </div>
                <div className=" w-[30%] p-8"></div>
            </div>
        </div>
        </>
     );
}
 
export default Prices;