import Hero from "../components/hero/hero";
import Navbar from "../components/navbar/navbar";
import Prices from "../components/price/price";
import hive from './assets/hive.svg';

const Landing = () => {
    return ( 
        <>
        <img src={ hive } className=" absolute top-0 right-0 z-0 hidden md:block w-[15%]" alt="" />
        <Navbar/>
        <Hero/>
        <Prices/>
        </>
     );
}
 
export default Landing;