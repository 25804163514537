import Landing from './pages/landing';

function App() {
  return (
    <div className="bg-[#121212] text-[#fff]">
      <Landing/>
    </div>
  );
}

export default App;
